<template>
  <div>
    <!-- 顶部 -->
    <div class="displya_flex">
      <!-- 左侧 -->
      <div></div>
      <!-- 右侧按钮 -->
      <div>
        <el-button @click="modParient(1)" class="color-main" type="primary">
          <i class="fa fa-search"></i>新增
        </el-button>
      </div>
    </div>

    <!-- 表格内容 -->
    <div>
      <el-table
        v-loading="loading"
        ref="multipleTableRef"
        :data="tableData"
        style="width: 100%"
        height="500"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column property="name" label="医疗文书名称" />
        <el-table-column property="recommend" label="简介" />
        <el-table-column fixed="right" label="操作" width="180">
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              @click="passwords(scope.row.id)"
            >
              <div class="icons">
                <img src="../../../assets/img/print.png" alt srcset />
              </div>
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="modParient(2, scope.row)"
            >
              <span class="txfonts modify">改</span>
            </el-button>
            <el-popconfirm
              title="是否确定删除?"
              @confirm="delParient(scope.row.id)"
            >
              <template #reference>
                <el-button type="text" size="small">
                  <span class="txfonts del">删</span>
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column> </el-table
      >、 、
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <div>
      <BaseDialog
        title="新增"
        :isshow="show"
        @handleShow="
          (val) => {
            show = val
          }
        "
        width="60%"
        height="auto"
      >
        <!-- 表单 -->
        <div>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
          >
            <el-form-item label="医疗文书名称:" prop="name">
              <el-input v-model="ruleForm.name" />
            </el-form-item>
            <el-form-item label="简称:">
              <el-input
                v-model="ruleForm.recommend"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
              />
            </el-form-item>
            <el-form-item>
              <el-alert
                :title="title"
                type="warning"
                show-icon
                :closable="false"
              />
            </el-form-item>
            <el-form-item label="签名:">
              <el-tag>Tag 1</el-tag>
            </el-form-item>
          </el-form>
        </div>
        <!-- 富文本 -->
        <div>
          <tinymce
            v-model:value="ruleForm.content_text"
            :height="300"
            @input="tinymce"
          />
        </div>
        <!-- 下划线 -->
        <el-divider />
        <!-- 底部按钮 -->
        <div class="displya_flex">
          <div></div>
          <el-row class="mb-4">
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="addcomfig">确定</el-button>
          </el-row>
        </div>
      </BaseDialog>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import Tinymce from '@/components/Tinymce'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BaseDialog,
    Tinymce,
  },
  setup() {
    const state = reactive({
      show: false,
      loading: true,
      ruleForm: {},
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      tableData: [],
      title:
        '可以点击以下按钮把相关信息插入到模板,在添加正式文书数据时将会自动替换为实际内容:',
    })
    onMounted(() => {
      tobody()
    })
    // 获取列表
    const tobody = async () => {
      state.loading = true
      let data = {
        size: state.pageData.pageSize,
        current: state.pageData.currentPage,
      }
      let res = await service.post('/api/medicaldocument/list', data)
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.tableData = res.data.record
          state.pageData.totalSum = res.data.total
        } else {
          state.tableData = []
        }
      }
      state.loading = false
    }
    // 条数改变时
    const handleSizeChange = (e) => {
      state.pageData.pageSize = e
      tobody()
    }
    // 页数改变时
    const handleCurrentChange = (e) => {
      state.pageData.currentPage = e
      tobody()
    }
    // 新增或修改
    const modParient = (index, row) => {
      state.ruleForm = {}
      console.log(row)
      if (index === 2) {
        state.ruleForm = row
      }
      state.show = true
    }
    // 新增表单或修改表单确定
    const addcomfig = async () => {
      if (!state.ruleForm.id) {
        let res = await service.post(
          '/api/medicaldocument/create',
          state.ruleForm
        )
        if (res.code === 0) {
          ElMessage({
            message: '添加成功',
            type: 'success',
          })
        }
      } else {
        let res = await service.post(
          '/api/medicaldocument/edit',
          state.ruleForm
        )
        if (res.code === 0) {
          ElMessage({
            message: '修改成功',
            type: 'success',
          })
        }
      }
      tobody()
      state.show = false
    } // 表单校验规则
    const rules = reactive({
      name: [
        {
          required: true,
          message: '此项必填,请输入',
        },
      ],
      scope_application: [
        {
          required: true,
          message: '此项必填,请输入',
        },
      ],
    })
    // 富文本内容
    const tinymce = (e) => {
      state.ruleForm.content_text = e
    }
    // 表格删除
    const delParient = async (id) => {
      let res = await service.post('/api/medicaldocument/delete', { id: id })
      if (res.code === 0) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
      }
      tobody()
    }
    return {
      ...toRefs(state),
      tobody,
      handleSizeChange,
      handleCurrentChange,
      modParient,
      rules,
      addcomfig,
      tinymce,
      delParient,
    }
  },
}
</script>

<style scoped lang="scss">
.displya_flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.icons {
  width: 22px;
  height: 22px;
}
.demo-ruleForm {
  margin-top: 20px;
}
.el-alert--warning.is-light {
  background-color: rgba(255, 62, 62, 0.2);
  color: rgba(255, 62, 62, 1);
}
.modify {
  background-color: #3166ae;
}
.del {
  background-color: #ffecec;
  color: #ff6b6b;
  font-weight: 700;
}
</style>
